<template>
  <div>
    <Row class="p-t-10 m-b-10" >
      <strong>费用汇总</strong>
    </Row>

    <i-table stripe  :data="products" :columns="productColumns"></i-table>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    productsPrice: Array
  },
  data () {
    return {
      productColumns: [
        { title: '资源名称', key: 'productName' },
        {
          title: '购买数量',
          align: 'center',
          render: (h, data) => {
            return h('span', data.row.quantity + ' ' + data.row.productUnit)
          }
        },
        {
          title: '媒体刊例总价',
          render: (h, data) => {
            return h('span', {
              attrs: {
                class: data.row.productName === '合计' ? 'text-green text-bold' : ''
              }
            }, this.formatMoney(data.row.amount))
          }
        },
        {
          title: '媒体发布费',
          render: (h, data) => {
            return h('div', [
              h('span', {
                attrs: {
                  class: data.row.productName === '合计' ? 'text-green text-bold' : ''
                }
              }, this.formatMoney(data.row.realAmount)),
              h('span', {
                attrs: {
                  class: data.row.productName === '合计' ? 'text-red' : ''
                }
              }, ' (' + this.computeDiscount(data.row) + '折)')

            ]

            )
          }
        },
        {
          title: '上下刊费',
          render: (h, data) => {
            return h('span', {
              attrs: {
                class: data.row.productName === '合计' ? 'text-green' : ''
              }
            }, this.formatMoney(data.row.installPrice))
          }
        },
        {
          title: '制作费',
          render: (h, data) => {
            return h('span', {
              attrs: {
                class: data.row.productName === '合计' ? 'text-green' : ''
              }
            }, this.formatMoney(data.row.producePrice))
          }
        },
        {
          title: '小计',
          render: (h, data) => {
            return h('span', {
              style: {
                color: data.row.productName === '合计' ? '#ed4014' : ''
              }
            }, this.formatMoney(data.row.total))
          }
        }
      ]
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    computeDiscount (params) {
      let discount = 0
      if (params.amount !== 0) {
        discount = ((params.realAmount / params.amount) * 10).toFixed(2)
        // const discountArr = discount.split('.')
        // const integer = discountArr[0]
        // const decimal = discountArr[1] ? discountArr[1] : '00'
        // discount = integer + '.' + decimal.substr(0, 2)
      }

      return discount
    }
  },
  computed: {
    products: function () {
      const items = new Map()
      let amountTotal = 0 // 刊例价
      let realAmountTotal = 0 // 媒体执行价
      let producePriceTotal = 0 // 制作费汇总
      let installPriceTotal = 0 // 上刊费汇总

      this.productsPrice.forEach(order => {
        order.settingProductfees.forEach(product => {
          const productPrice = items.get(product.goodsId) || {}
          let quantity = productPrice.quantity || 0
          let amount = productPrice.amount || 0 // 刊例价
          let realAmount = productPrice.realAmount || 0 // 最终执行价
          let producePrice = productPrice.producePrice || 0 // 原有制作费
          let installPrice = productPrice.installPrice || 0 // 原有上刊费

          // 获取当前产品内的费用
          quantity += product.quantity || 0
          amount += product.tusePrice || 0
          realAmount += product.usePrice || 0
          producePrice += product.producePrice || 0
          installPrice += product.installPrice || 0

          // 合计总价， 汇总添加当前产品的费用
          producePriceTotal += product.producePrice || 0
          installPriceTotal += product.installPrice || 0
          realAmountTotal += product.usePrice || 0
          amountTotal += product.tusePrice || 0

          const productSummary = {
            productName: product.goodsName,
            quantity: quantity,
            realAmount: realAmount,
            amount: amount,
            producePrice: producePrice,
            installPrice: installPrice,
            total: realAmount + producePrice + installPrice,
            productUnit: product.productUnit
          }
          items.set(product.goodsId, productSummary)
        })
      })
      const summary = [...items.values()]
      summary.push({
        productName: '合计',
        quantity: '',
        realAmount: realAmountTotal,
        amount: amountTotal,
        producePrice: producePriceTotal,
        installPrice: installPriceTotal,
        total: realAmountTotal + producePriceTotal + installPriceTotal,
        productUnit: ''
      })
      return summary
    }

  }

}
</script>
